




































































































import { ConfirmModal, TwButton } from '@/app/components';
import { useAxios } from '@/app/composable';
import store from '@/app/store';
import { ContractsAPI } from '@/modules/sharing/api';
import { Contract } from '@/modules/sharing/types';
import { ChevronRightIcon, ExclamationIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { RetrievalQueryAPI } from '../api';
import { RetrievalAccessibility, RetrievalAccessibilityType } from '../constants';
import { RetrievalConfiguration, SearchResult as SearchResultInterface } from '../interfaces';
import SearchAvailableResults from './SearchAvailableResults.vue';

export default defineComponent({
    name: 'FileSearchResultConfiguration',
    components: {
        ChevronRightIcon,
        TwButton,
        SearchAvailableResults,
        ExclamationIcon,
        ConfirmModal,
    },
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        uid: {
            type: String,
            required: true,
        },
        configuration: {
            type: Object as PropType<RetrievalConfiguration>,
            default: () => {
                return {
                    params: {},
                    datasets: [],
                    download: 'direct',
                };
            },
        },
        accessibility: {
            type: Object as PropType<RetrievalAccessibilityType>,
            required: true,
        },
        assets: {
            type: Array as PropType<SearchResultInterface[]>,
            required: true,
        },
        allAssets: {
            type: Array as PropType<SearchResultInterface[]>,
            required: true,
        },
        totalResults: {
            type: Number,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { root, emit }) {
        const { exec } = useAxios(true);

        const selectedAssetIds: Ref<number[]> = ref<number[]>([]);
        const showAssets: Ref<boolean> = ref<boolean>(true);
        const showConfirmModal = ref<boolean>(false);
        const showInfoModal = ref<boolean>(false);
        const selectedDownload: Ref<string | undefined> = ref<string | undefined>();
        const contractId = ref<string | null>(null);

        const selectedAssets = computed(() =>
            props.allAssets.filter((asset: SearchResultInterface) => selectedAssetIds.value.includes(asset.id)),
        );

        const user = computed(() => store.state.auth.user);

        const isAcquired = (result: { createdBy: { id: number; organisationId: number | null }; id: number }) => {
            return result.createdBy.organisationId && user.value.organisationId
                ? result.createdBy.organisationId !== user.value.organisationId
                : result.createdBy.id !== user.value.id;
        };

        const isOther = (result: {
            structure?: { type?: string; primaryConcept?: { uid: string; name: string } | null };
        }) => {
            return result.structure?.type === 'other' || !result.structure?.primaryConcept;
        };

        const selectedAssetIsAcquired = computed(
            () => selectedAssets.value.length > 0 && isAcquired(selectedAssets.value[0]),
        );

        const selectedAssetIsAcquiredAndNotOther = computed(
            () => selectedAssetIsAcquired.value && !isOther(selectedAssets.value[0]),
        );

        const retrievalQueryConfiguration: Ref<RetrievalConfiguration> = computed(() => {
            return {
                datasets: selectedAssetIds.value.map((id: number) => ({
                    id: `${id}`,
                    fields: [],
                    contractId: selectedAssetIsAcquired.value ? contractId.value : null,
                })),
                download: selectedDownload.value || 'direct',
                join: {},
                params: {},
                uploadRequestedAt: props.configuration.uploadRequestedAt,
                acquired: selectedAssetIsAcquiredAndNotOther.value ? true : undefined,
            };
        });

        const processConfiguration = (newConfiguration: RetrievalConfiguration) => {
            const newAssetIds = newConfiguration.datasets
                ? newConfiguration.datasets.map((asset: { id: string; fields: string[] }) => parseInt(asset.id, 10))
                : [];

            if (JSON.stringify(newAssetIds) !== JSON.stringify(selectedAssetIds.value))
                selectedAssetIds.value = newAssetIds;

            const newDownload = newConfiguration.download ? newConfiguration.download : 'direct';
            if (newDownload !== selectedDownload.value) selectedDownload.value = newDownload;
        };

        const atLeastOneFileSelected = computed(() => selectedAssetIds.value.length > 0);

        const saveAndProceed = () => {
            if (selectedAssetIsAcquiredAndNotOther.value) {
                exec(RetrievalQueryAPI.getRetrievalQueryURLs(props.uid))
                    .then((res: any) => {
                        if (res.data && res.data.length > 0) emit('next', res.data);
                        else if (props.configuration.uploadRequestedAt) showInfoModal.value = true;
                        else showConfirmModal.value = true;
                    })
                    .catch(() => {
                        (root as any).$toastr.e(
                            'Something went wrong while trying to retrieve retrieval query urls',
                            'Error',
                        );
                    });
            } else emit('next');
        };

        const uploadAcquiredAsset = () => {
            if (selectedAssets.value.length > 0)
                exec(
                    RetrievalQueryAPI.storeAcquiredAssetInMinio(props.uid, {
                        configuration: retrievalQueryConfiguration.value,
                        assets: selectedAssetIds.value,
                    }),
                )
                    .then(() => {
                        emit('refetch');
                        showConfirmModal.value = false;
                        (root as any).$toastr.s(
                            'The request for preparing the asset to download has been successfully submitted',
                            'Success',
                        );
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Something went wrong while trying to upload acquired asset', 'Error');
                        showConfirmModal.value = false;
                    });
        };

        const acquiredAssetAdded = () => {
            if (selectedAssetIsAcquiredAndNotOther.value) selectedDownload.value = 'direct';
            exec(ContractsAPI.getByAssetIds(selectedAssetIds.value.join(','))).then((res) => {
                const foundContract = res?.data.find(
                    (contract: Contract) => contract.assetId == selectedAssetIds.value[0],
                );
                if (foundContract) contractId.value = foundContract.id;
            });
        };

        watch(
            () => retrievalQueryConfiguration.value,
            (configuration: RetrievalConfiguration, oldConfiguration: RetrievalConfiguration) => {
                if (JSON.stringify(configuration) !== JSON.stringify(oldConfiguration)) {
                    emit('change', configuration);
                }
            },
            { deep: true },
        );

        watch(
            () => props.configuration,
            (newConfiguration: RetrievalConfiguration) => {
                processConfiguration(newConfiguration);
            },
            { immediate: true, deep: true },
        );

        return {
            selectedAssetIds,
            selectedAssets,
            showAssets,
            RetrievalAccessibility,
            atLeastOneFileSelected,
            selectedDownload,
            saveAndProceed,
            uploadAcquiredAsset,
            selectedAssetIsAcquiredAndNotOther,
            showConfirmModal,
            showInfoModal,
            acquiredAssetAdded,
        };
    },
});
