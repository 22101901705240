





















































































































import { Pagination, Scrollbar, SearchBox, TwSelect } from '@/app/components';
import { useAxios } from '@/app/composable';
import store from '@/app/store';
import { ChevronLeftIcon, ChevronRightIcon, FilterIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, onBeforeUnmount, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { RetrievalQueryAPI } from '../api';
import { useRetrievalSearchAssets } from '../composable';
import { RetrievalAccessibility, RetrievalAccessibilityType, RetrievalSortingOption } from '../constants';
import { RetrievalQuery, SearchResult as SearchResultInterface } from '../interfaces';
import FakeSearchResult from './FakeSearchResult.vue';
import SearchResult from './SearchResult.vue';

export default defineComponent({
    name: 'SearchAvailableResults',
    components: {
        SearchBox,
        Pagination,
        FakeSearchResult,
        SearchResult,
        Scrollbar,
        ChevronRightIcon,
        ChevronLeftIcon,
        TwSelect,
        FilterIcon,
    },
    model: {
        prop: 'selected',
        event: 'change',
    },
    props: {
        uid: {
            type: String,
            required: true,
        },
        selected: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        assets: {
            type: Array as PropType<SearchResultInterface[]>,
            required: true,
        },
        totalResults: {
            type: Number,
            required: true,
        },
        accessibility: {
            type: Object as PropType<RetrievalAccessibilityType>,
            required: true,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit, root }) {
        const openFilters = ref(true);

        const {
            page,
            query,
            sortBy,
            unsubscribeSearch,
            sortByChanged,
            onEnter,
            clearSearch,
            updateCurrentPage,
        } = useRetrievalSearchAssets(root, emit);

        onBeforeUnmount(() => (unsubscribeSearch.value ? unsubscribeSearch.value() : undefined));

        const sortingFields = RetrievalSortingOption.all();
        const pageSize = 10;
        const availableResultsElement = ref<any>();
        const showAssets: Ref<boolean> = ref<boolean>(true);
        const acquiredAssetIds = ref<number[]>([]);

        const user = computed(() => store.state.auth.user);

        const selectedAssetIds = computed({
            get: () => props.selected,
            set: (updatedSelection: number[]) => {
                emit('change', updatedSelection);
            },
        });

        const hideButton = (result: { id: number }) => {
            return (
                props.accessibility.key === RetrievalAccessibility.FILE &&
                !selectedAssetIds.value.includes(result.id) &&
                (selectedAssetIds.value.length > 0 || acquiredAssetIds.value.includes(result.id))
            );
        };

        const isAcquired = (result: { id: number; createdBy: { id: number; organisationId: number | null } }) => {
            return result.createdBy.organisationId && user.value.organisationId
                ? result.createdBy.organisationId !== user.value.organisationId
                : result.createdBy.id !== user.value.id;
        };

        const add = (result: { id: number; createdBy: { id: number; organisationId: number | null } }) => {
            selectedAssetIds.value.push(result.id);
            if (props.accessibility.key === RetrievalAccessibility.FILE && isAcquired(result))
                emit('acquired-asset-added');
        };

        const remove = (result: { id: number }) => {
            selectedAssetIds.value = selectedAssetIds.value.filter((assetId: number) => assetId !== result.id);
        };

        const { exec } = useAxios(true);

        if (props.accessibility.key === RetrievalAccessibility.FILE) {
            exec(RetrievalQueryAPI.getAcquiredAssetQueries()).then((res) => {
                if (res?.data)
                    acquiredAssetIds.value = R.uniq(
                        res.data.reduce(
                            (acc: number[], rq: RetrievalQuery) =>
                                rq.id !== props.uid ? R.concat(acc, rq.assetIds ?? []) : acc,
                            [],
                        ),
                    );
            });
        }

        return {
            page,
            query,
            sortBy,
            remove,
            add,
            selectedAssetIds,
            pageSize,
            showAssets,
            uuidv4,
            RetrievalAccessibility,
            sortingFields,
            openFilters,
            availableResultsElement,
            sortByChanged,
            onEnter,
            clearSearch,
            updateCurrentPage,
            hideButton,
        };
    },
});
