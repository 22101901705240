





























































































import { useFilters } from '@/app/composable/filters';
import { AssetTypeIcon } from '@/modules/asset/components';
import { useAsset } from '@/modules/asset/composable/asset';
import { AssetType, AssetTypeId } from '@/modules/asset/constants';
import { CalendarIcon, CollectionIcon, DownloadIcon, PlusIcon, UserIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import * as R from 'ramda';
import { RetrievalAccessibility, RetrievalAccessibilityType, StreamingStatus } from '../constants';
import { SearchResult } from '../interfaces';

export default defineComponent({
    name: 'SearchResult',
    props: {
        result: {
            type: Object as PropType<SearchResult>,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        accessibility: {
            type: Object as PropType<RetrievalAccessibilityType>,
            required: true,
        },
        hideButton: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: { CalendarIcon, UserIcon, CollectionIcon, PlusIcon, DownloadIcon, AssetTypeIcon },
    setup(props) {
        const { fromNow, formatDateTime } = useFilters();
        const { assetTypeClass, assetTypeName } = useAsset();

        const copyrightOwner: any = computed(() => {
            if (props.result.metadata.license?.copyrightOwner) {
                return props.result.metadata.license.copyrightOwner;
            }
            return props.result.createdBy.firstName;
        });

        const enabled = (type: string) => {
            if (
                type === RetrievalAccessibility.STREAMING &&
                props.result.streamingStatus &&
                props.result.streamingStatus === StreamingStatus.Completed
            ) {
                return false;
            }
            return true;
        };

        const assetType = computed(() => {
            switch (props.result.assetTypeId) {
                case AssetTypeId.Model:
                    return {
                        type: AssetType.Model,
                        class: 'bg-purple-100 text-purple-600',
                    };
                case AssetTypeId.Result:
                    return {
                        type: AssetType.Result,
                        class: 'bg-indigo-100 text-indigo-600',
                    };
                default:
                    return {
                        type: AssetType.Dataset,
                        class: 'bg-orange-100 text-orange-600',
                    };
            }
        });

        return {
            fromNow,
            enabled,
            copyrightOwner,
            assetTypeClass,
            assetTypeName,
            RetrievalAccessibility,
            assetType,
            formatDateTime,
            R,
        };
    },
});
