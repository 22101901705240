import { useFacetsFilters, useSearch } from '@/app/composable';
import { SearchAPI } from '@/modules/search/api';
import { computed, onBeforeUnmount, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { RetrievalQueryAPI } from '../api';
import { RetrievalQueriesSortingOption } from '../constants/retrieval-queries-sorting-options.constants';
import { RetrievalQuery } from '../interfaces';
import { useRetrievalQueryFacets } from './retrieval-query-facets.constants';
import { useRetrievalQueryFilters } from './retrieval-query-filters.constants';

export function useRetrievalQueries(root: any, routeName: string, pageSize: number) {
    const retrievalQueries = ref<RetrievalQuery[]>([]);

    const totalResults = ref<number>(0);
    const facets = ref<Record<string, { value: string; count: number; selected: boolean }[]>>({});
    const assetOptions = ref<{ id: number; label: string }[] | null>(null);
    const otherOptions = computed(() => ({ asset: assetOptions.value ?? [] }));

    const setSearchQuery = () => {
        searchQuery.value.facets = reducedRetrievalQueryFacets;
        searchQuery.value.filters = reducedRetrievalQueryFilters;
    };

    const search = () => {
        setCommonSearchQueryValues();
        setQueryParams();

        return new Promise((resolve, reject) => {
            exec(SearchAPI.retrievalQueries(searchQuery.value))
                .then((res: any) => {
                    retrievalQueries.value = res.data.results;
                    totalResults.value = res.data.total;
                    facets.value = res.data.facets;
                    if (R.isNil(assetOptions.value))
                        assetOptions.value = res.data.results.reduce(
                            (acc: { id: number; label: string }[], result: RetrievalQuery) => {
                                result.assets?.forEach((asset: { id: number; name: string }) => {
                                    if (!acc.find((item: { id: number; label: string }) => item.id === asset.id))
                                        acc.push({ id: asset.id, label: asset.name });
                                });
                                return acc;
                            },
                            [],
                        );
                    resolve(res.data.data);
                })
                .catch((e) => reject(e));
        });
    };

    const {
        get,
        exec,
        loading,
        error,
        page,
        sortBy,
        tab,
        query,
        searchQuery,
        setTab,
        updateCurrentPage,
        sortByChanged,
        setCommonSearchQueryValues,
        setQueryParams,
        unsubscribeSearch,
        sortingFields,
        facetChanged,
        filterChanged,
        clearFilters,
    } = useSearch(root, routeName, pageSize, RetrievalQueriesSortingOption, 'all', setSearchQuery, search);

    const { retrievalQueryFacets, reducedRetrievalQueryFacets } = useRetrievalQueryFacets(get);
    const { retrievalQueryFilters, reducedRetrievalQueryFilters } = useRetrievalQueryFilters(get);

    onBeforeUnmount(() => {
        unsubscribeSearch.value ? unsubscribeSearch.value() : undefined;
    });

    const errors = computed(() => {
        const errorsList = [];
        if (error.value) {
            errorsList.push(error.value.message);
        }

        return errorsList;
    });

    const deleteRetrievalQuery = (retrievalQuery: RetrievalQuery): Promise<void> => {
        return new Promise((resolve, reject) => {
            exec(RetrievalQueryAPI.delete(retrievalQuery.id))
                .then(async () => {
                    await search();
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };

    const getUploadRequestedQueries = (): Promise<string[]> => {
        return new Promise((resolve, reject) => {
            exec(RetrievalQueryAPI.getUploadRequestedQueries())
                .then((res) => {
                    resolve(res?.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };

    const { calculateSearchedFacets, calculateSearchedFilters } = useFacetsFilters([], otherOptions);

    const currentSearchedFacetsFilters = computed(() =>
        calculateSearchedFacets(searchQuery.value.facets, retrievalQueryFacets.value).concat(
            calculateSearchedFilters(searchQuery.value.filters, retrievalQueryFilters.value),
        ),
    );

    setSearchQuery();
    search();

    return {
        page,
        sortBy,
        retrievalQueries,
        errors,
        loading,
        search,
        deleteRetrievalQuery,
        query,
        tab,
        totalResults,
        updateCurrentPage,
        sortByChanged,
        setTab,
        sortingFields,
        currentSearchedFacetsFilters,
        facetChanged,
        filterChanged,
        clearFilters,
        searchQuery,
        facets,
        retrievalQueryFacets,
        retrievalQueryFilters,
        assetOptions,
        getUploadRequestedQueries,
    };
}
