















































































































































































import { CopyToClipboardButton, JsonEditor, SvgImage, TwAccordion, TwAccordionCard } from '@/app/components';
import { useAxios, useFeatureFlags } from '@/app/composable';
import { AccessTokenScope } from '@/app/constants';
import AccessTokenModal from '@/modules/user/views/AccessTokenModal.vue';
import { DocumentIcon } from '@vue-hero-icons/outline';
import { defineComponent, PropType, ref, Ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { RetrievalQueryAPI } from '../api';
import { RetrievalAccessibilityType } from '../constants';
import { RetrievalConfiguration } from '../interfaces';

export default defineComponent({
    name: 'FileResultsPreview',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        uid: { type: String, required: true },
        configuration: {
            type: Object as PropType<RetrievalConfiguration>,
            default: () => {
                return {
                    params: {},
                    datasets: [],
                    download: 'direct',
                    kafkaConnectionDetails: {},
                    binaryConceptSelected: false,
                };
            },
        },
        accessibility: {
            type: Object as PropType<RetrievalAccessibilityType>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        fileUrls: {
            type: Array as PropType<{ file: string; url: string }[]>,
            default: () => [],
        },
    },
    components: {
        AccessTokenModal,
        DocumentIcon,
        SvgImage,
        JsonEditor,
        OrbitSpinner,
        TwAccordion,
        TwAccordionCard,
        CopyToClipboardButton,
    },
    setup(props, { root }) {
        const { exec, error, loading: urlsLoading } = useAxios(true);
        const { areAnyEnabled } = useFeatureFlags();
        const apiUrl = process.env.VUE_APP_BACKEND_URL || '';
        const showAccessTokenModal: Ref<boolean> = ref<boolean>(false);
        const assetFileUrls: Ref<{ file: string; url: string }[]> = ref<{ file: string; url: string }[]>(
            props.fileUrls,
        );
        const response: Ref<string | undefined> = ref<string | undefined>();
        const datasetId =
            props.configuration.datasets && props.configuration.datasets.length === 1
                ? parseInt(props.configuration.datasets[0].id, 10)
                : undefined;

        const availableScopes = [AccessTokenScope.Retrieve]
            .filter((scope) => areAnyEnabled(scope.features))
            .map((scope) => {
                return { ...scope, checked: true, disabled: true };
            });

        // can only ever be one
        if (datasetId) {
            if (assetFileUrls.value.length === 0) {
                if (props.configuration.uploadRequestedAt) {
                    exec(RetrievalQueryAPI.getRetrievalQueryURLs(props.uid)).then((res: any) => {
                        assetFileUrls.value = res.data;
                    });
                } else {
                    const path =
                        props.configuration.download === 'indirect'
                            ? `api/retrieval/${props.uid}/file`
                            : 'api/retrieval/file';

                    exec(RetrievalQueryAPI.getURLs(datasetId, path))
                        .then((res: any) => {
                            assetFileUrls.value = res.data;
                            response.value = JSON.stringify(assetFileUrls.value, null, '\t');
                        })
                        .catch((e: any) => {
                            if (e?.response?.status === 403) {
                                (root as any).$toastr.e('Error while retrieving file URLs!', 'Error');
                                root.$router.push({ name: 'retrieval' });
                            }
                        });
                }
            }
        }

        return {
            apiUrl,
            datasetId,
            assetFileUrls,
            showAccessTokenModal,
            error,
            response,
            urlsLoading,
            availableScopes,
        };
    },
});
